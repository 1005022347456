import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/layout";

// 固定页面
import constantRouter from "./modules/constant";
// 权限页面
import dashBoardRouter from "./modules/dashboard";
import wechatRouter from "./modules/wechat";
import systemRouter from "./modules/system";
import xiaoeRouter from "./modules/xiaoetong";
import interactionRouter from "./modules/interaction";
import myRouter from "./modules/my";

export const dynamicRoutes = [
  ...dashBoardRouter,
  ...wechatRouter,
  ...xiaoeRouter,
  ...interactionRouter,
  ...systemRouter,
  ...myRouter,
];

const extra = [
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
  },
  { path: "/:catchAll(.*)", hidden: true, redirect: "/404" },
];
export const routes = constantRouter.concat(dynamicRoutes).concat(extra);

export default createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({
    top: 0,
  }),
  routes,
});
