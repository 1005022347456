import storage from "@/utils/storage";

class Core {
  USER_INFO = "userinfo";
  CURRENT_WX_ID = "current_wx_id";

  clear_user_info() {
    return storage.clear(this.USER_INFO);
  }

  set_user_info(user_info) {
    return storage.set(this.USER_INFO, JSON.stringify(user_info));
  }

  get_user_info() {
    return storage.get(this.USER_INFO)
      ? JSON.parse(storage.get(this.USER_INFO))
      : null;
  }

  get_user_info_json() {
    const user_info = this.get_user_info();
    if (user_info) {
      return user_info;
    } else {
      return null;
    }
  }

  get_token() {
    const user_info = this.get_user_info();

    if (user_info) {
      const j = user_info;
      const token = j.token;
      return token;
    } else {
      return null;
    }
  }

  get_open_id() {
    return process.env.VUE_APP_OPEN_ID;
  }

  get_avatar() {
    const user_info = this.get_user_info();
    if (user_info) {
      const j = user_info;
      const avatar = j.avatar;
      return avatar;
    } else {
      return "";
    }
  }

  get_realname() {
    const user_info = this.get_user_info();
    if (user_info) {
      const j = user_info;
      const realname = j.realname;
      return realname;
    } else {
      return "";
    }
  }

  clear_current_wx_id() {
    return storage.clear(this.CURRENT_WX_ID);
  }

  set_current_wx_id(wx_id) {
    return storage.set(this.CURRENT_WX_ID, wx_id);
  }

  get_current_wx_id() {
    return storage.get(this.CURRENT_WX_ID);
  }
}

const core = new Core();

export default core;
