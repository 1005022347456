import layoutMoreRoute from '@/layout_moreRoute';

const interactionRouter = [
  {
    path: '/interaction',
    component: layoutMoreRoute,
    redirect: '/interaction/investigators/list',
    name: 'interaction',
    meta: {
      title: '医评互动',
      icon: 'form',
      auth: 'survey'
    },
    children: [
      {
        path: 'investigators',
        component: layoutMoreRoute,
        redirect: '/interaction/investigators/list',
        name: 'investigators',
        meta: {
          title: '志愿者管理',
          icon: 'peoples',
          auth: 'survey_volunteer_manage'
        },
        children: [
          {
            path: 'list',
            component: () =>
              import(
                '@/modules/interaction/views/investigatorsManage/investigatorsList'
              ),
            name: 'investigators-list',
            meta: {
              title: '志愿者列表',
              noCache: true,
              auth: 'survey_volunteer_list'
            }
          }
        ]
      },
      {
        path: 'location',
        component: layoutMoreRoute,
        redirect: '/interaction/location/list',
        name: 'location',
        meta: {
          title: '地点管理',
          icon: 'example',
          auth: 'survey_location_manage'
        },
        children: [
          {
            path: 'list',
            component:
              () =>
                import(
                  '@/modules/interaction/views/locationManage/locationList'
                ),
            name: 'location-list',
            meta: {
              title: '地点列表',
              noCache: true,
              auth: 'survey_location_list'
            }
          }
        ]
      },
      {
        path: 'function',
        component: layoutMoreRoute,
        redirect: '/interaction/function/list',
        name: 'function',
        meta: {
          title: '功能管理',
          icon: 'list',
          auth: 'survey_function_manage'
        },
        children: [
          {
            path: 'list',
            component:
              () =>
                import(
                  '@/modules/interaction/views/functionManage/functionList'
                ),
            name: 'function-list',
            meta: {
              title: '功能列表',
              noCache: true,
              auth: 'survey_function_list'
            }
          }
        ]
      },
      {
        path: 'questionnaire',
        component: layoutMoreRoute,
        redirect: '/interaction/questionnaire/list',
        name: 'questionnaire',
        meta: {
          title: '问卷管理',
          icon: 'documentation',
          auth: 'survey_questionnaire_manage'
        },
        children: [
          {
            path: 'list',
            component:
              () =>
                import(
                  '@/modules/interaction/views/questionnaireManage/questionnaireList'
                ),
            name: 'questionnaire-list',
            meta: {
              title: '问卷列表',
              noCache: true,
              auth: 'survey_questionnaire_list'
            }
          },
          {
            path: 'questionList',
            component:
              () =>
                import(
                  '@/modules/interaction/views/questionnaireManage/questionList'
                ),
            name: 'questionList',
            meta: {
              title: '问卷问题列表',
              noCache: true,
              auth: 'survey_question_list'
            },
            hidden: true
          }
        ]
      },
      {
        path: 'task',
        component: layoutMoreRoute,
        redirect: '/interaction/task/list',
        name: 'task',
        meta: {
          title: '任务管理',
          icon: 'skill',
          auth: 'survey_task_manage'
        },
        children: [
          {
            path: 'list',
            component:
              () => import('@/modules/interaction/views/taskManage/taskList'),
            name: 'task-list',
            meta: {
              title: '任务列表',
              noCache: true,
              auth: 'survey_task_list'
            }
          },
          {
            path: 'staticTaskList',
            component:
              () =>
                import('@/modules/interaction/views/taskManage/staticTaskList'),
            name: 'static-task-list',
            meta: {
              title: '静态任务列表',
              noCache: true,
              auth: 'survey_static_task_list'
            }
          }
        ]
      },
      {
        path: 'banner',
        component: layoutMoreRoute,
        redirect: '/interaction/banner/list',
        name: 'banner',
        meta: {
          title: '轮播管理',
          icon: 'banner',
          auth: 'survey_banner_manage'
        },
        children: [
          {
            path: 'list',
            component:
              () =>
                import(
                  '@/modules/interaction/views/bannerManage/bannerManageList'
                ),
            name: 'banner-list',
            meta: {
              title: '轮播管理',
              noCache: true,
              auth: 'survey_banner_list'
            }
          }
        ]
      },
      {
        path: 'statistics',
        component: layoutMoreRoute,
        redirect: '/statistics/resultList',
        name: 'statistics',
        meta: {
          title: '统计管理',
          icon: 'chart',
          auth: 'survey_statistics_manage'
        },
        children: [
          {
            path: 'resultList',
            component: () =>
              import(
                '@/modules/interaction/views/statisticsManage/resultList'
              ),
            name: 'result-ist',
            meta: {
              title: '答卷列表',
              noCache: true,
              auth: 'survey_statistics_result_list'
            }
          },
          {
            path: 'resultDataExport',
            component: () =>
              import(
                '@/modules/interaction/views/statisticsManage/resultDataExport'
              ),
            name: 'result-data-export',
            meta: {
              title: '问卷数据导出',
              noCache: true,
              auth: 'survey_statistics_result_data_exportList'
            }
          }
        ]
      },
      {
        path: 'setup',
        component: layoutMoreRoute,
        redirect: '/setup/bindSetup',
        name: 'setup',
        meta: {
          title: '设置管理',
          icon: 'setting',
          auth: 'survey_setup_manage'
        },
        children: [
          {
            path: 'bindSetup',
            component: () => import('@/modules/interaction/views/setupManage/bindSetup'),
            name: 'bind_setup',
            meta: {
              title: '绑定设置',
              noCache: true,
              auth: 'survey_setup_manage_bindSetup'
            }
          }
        ]
      }
    ]
  }
];

export default interactionRouter;
