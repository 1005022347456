// import constantRouter from '@/router/modules/constant'
import { dynamicRoutes } from '@/router';

const state = {
  authObj: localStorage.getItem('umadmin-auth')
    ? JSON.parse(localStorage.getItem('umadmin-auth'))
    : null,
  routes: localStorage.getItem('umadmin-auth-routes')
    ? JSON.parse(localStorage.getItem('umadmin-auth-routes'))
    : []
};
const mutations = {
  SET_AUTH: (state, val) => {
    state.authObj = val;
  },
  SET_All_ROUTES: (state, val) => {
    state.routes = val;
  }
};

const actions = {
  setAuth({ commit }, val) {
    localStorage.setItem('umadmin-auth', JSON.stringify(val));
    commit('SET_AUTH', val);
  },

  setAllRoutes({ commit }, val) {
    // 保存权限路由表操作
    const routes = dynamicRoutes.filter((item) => {
      if (val.includes(item.meta.auth) || item.meta.noNeedAuth) {
        if (Array.isArray(item.children)) {
          item.children = item.children.filter(
            (option) => val.includes(option.meta.auth) || option.meta.noNeedAuth
          );

          item.children.forEach((childItem) => {
            if (Array.isArray(childItem.children)) {
              childItem.children = childItem.children.filter(
                (childOption) =>
                  val.includes(childOption.meta.auth) ||
                  childOption.meta.noNeedAuth
              );
            }
          });
        }
        return item;
      }
    });
    localStorage.setItem('umadmin-auth-routes', JSON.stringify(routes));
    commit('SET_All_ROUTES', routes);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
